.welcome-area {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}
.surveyor-category-table {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.admin-home {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}
.surveyor-loader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}