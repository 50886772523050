.home-container {
  display: flex;
  flex: 1;
  height: 100vh;
  background: linear-gradient(107.54deg, #262261 36.98%, #0F111B 100%);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.brand-container {
  display: flex;
  background-color: #f2f3f6;
  width: 25vw;
  transform: perspective(1361px) rotateY(12deg) translate(-8px, 0px) scale(0.96);
}

.menu-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 40px;
}

.menu-row {
  display: flex;
}

.row-margin {
  margin-top: 40px;
  margin-bottom: 40px;
}

.left-margin {
  margin-left: 40px;
}

.brand-image {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 7rem 0;
}

.airport-logo {
  width: 160px;
}

.inetra-logo {
  width: 220px;
}

.copyright-div {
  display: flex;
  width: 112px;
  border-right: 1px solid rgba(37, 33, 92, 0.3);
}

.copyright-text {
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  position: absolute;
  top: 280px;
  left: -112px;
}