.aixm-banner {
  width: 100%;
  height: calc(97.9vh - 77px);
}

.aixm-category-table {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.aixm-airport-sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}