/* main */
.wrapper {
  display: flex;
  flex-direction: row;
}

.main {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 272px);
  height: 97.9vh;
  padding: 0.5rem;
}

.content-area {
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative;
  border-radius: 5px;
  background-color: #ffffff;
  overflow: auto;
}

.echart-content {
  height: 100vh;
  display: flex;
  overflow: auto;
  background-color: #ffffff;
}

.crc-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #ffffff;
}

.echart-content-area {
  position: relative;
  flex: 1;
  border-radius: 5px;
  background-color: #dddddd;
  overflow: auto;
}

.flex-direction-column {
  flex-direction: column;
}

.linear-bg {
  background: linear-gradient(107.54deg, #262261 36.98%, #0f111b 100%);
}

.white-bg {
  background: #ffffff;
}

.pt-55 {
  padding-top: 55px;
}

.full-screen-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.full-screen-loader-content {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* sidebar */
.sidebar {
  background-color: #ffffff;
  width: 272px;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  padding: 10px;
}

.check-list-header {
  margin-bottom: 12px;
}

.echart-list-header {
  margin-bottom: 12px;
}

.check-list-action {
  display: flex;
  justify-content: space-between;
}

.check-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  overflow: auto;
  word-break: break-all;
  padding-bottom: 30px;
}

.aixm-sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.surveyor-sidebar-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 241px);
}

.admin-sidebar-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 170px);
}

.login-sidebar-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 150px);
}

.amdb-sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.etod-sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aixm-check-list {
  height: calc(100vh - 460px);
}

.airspace-check-list {
  height: calc(100vh - 426px);
}

.airspace-check-list-2 {
  height: calc(100vh - 460px);
}

.airspace-check-list-3 {
  height: calc(100vh - 495px);
}

.airspace-check-list-4 {
  height: calc(100vh - 565px);
}

.airspace-check-list-5 {
  height: calc(100vh - 175px);
}

.etod-check-list-area {
  height: calc(100vh - 454px);
}

.amdb-check-list {
  height: calc(100vh - 378px);
}

.echart-check-list {
  height: calc(100vh - 389px);
}

/* header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 1vh;
  padding: 0.5rem;
  border-radius: 5px;
  padding-left: 50px;
}

/* nav */
.active-menu {
  color: #124444;
  font-size: 18px;
  font-weight: bolder;
}

.in-active-menu {
  color: #808080;
}

/* brand logo */
.logo-brand {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
  cursor: pointer;
}

.satsure-img {
  width: 100%;
  height: auto;
}

/* leaflet map */
.leaflet-container {
  width: 100%;
}

.icon-export-print {
  cursor: pointer;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMTI4LDMyaDI1NnY2NEgxMjhWMzJ6IE00ODAsMTI4SDMyYy0xNy42LDAtMzIsMTQuNC0zMiwzMnYxNjBjMCwxNy42LDE0LjM5OCwzMiwzMiwzMmg5NnYxMjhoMjU2VjM1Mmg5NiAgIGMxNy42LDAsMzItMTQuNCwzMi0zMlYxNjBDNTEyLDE0Mi40LDQ5Ny42LDEyOCw0ODAsMTI4eiBNMzUyLDQ0OEgxNjBWMjg4aDE5MlY0NDh6IE00ODcuMTk5LDE3NmMwLDEyLjgxMy0xMC4zODcsMjMuMi0yMy4xOTcsMjMuMiAgIGMtMTIuODEyLDAtMjMuMjAxLTEwLjM4Ny0yMy4yMDEtMjMuMnMxMC4zODktMjMuMiwyMy4xOTktMjMuMkM0NzYuODE0LDE1Mi44LDQ4Ny4xOTksMTYzLjE4Nyw0ODcuMTk5LDE3NnoiIGZpbGw9IiMwMDAwMDAiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
}