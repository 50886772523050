.nav-button-container {
  width: 193px;
  height: 175px;
  background: linear-gradient(129.43deg, #302b9b 21.35%, #235eac 100%);
  box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.button-label {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.button-line {
  height: 1px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 10px;
}
.side-rect {
  background: #bababa;
  border-radius: 20px;
  position: relative;
  left: -20px;
  top: -20px;
  width: 5px;
  height: 47px;
}
.icon-row {
  flex-direction: row;
  display: flex;
}