.login-area {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.login-box {
  background: #ffffff;
  box-shadow: 4px 4px 8px 3px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  width: 450px;
  height: 400px;
}
.login-box-header {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.forgot-password {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.login-footer {
  display: flex;
  justify-content: center;
}
.p-2 {
  padding: 20px;
}
.p-5 {
  padding: 50px;
}
.mt-4 {
  margin-top: 30px;
}
