.aero-home-container {
  display: flex;
  flex: 1;
  height: 100vh;
  background: linear-gradient(107.54deg, #262261 36.98%, #0f111b 100%);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.aero-brand-container {
  display: flex;
  background-color: #f2f3f6;
  width: 25vw;
  transform: perspective(1361px) rotateY(12deg) translate(-8px, 0px) scale(0.96);
}

.aero-menu-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 40px;
}

.aero-menu-row {
  display: flex;
}

.aero-row-margin {
  margin-top: 40px;
}

.aero-left-margin {
  margin-left: 40px;
}

.aero-brand-image {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 80px 0;
}

.aero-airport-logo {
  width: 160px;
}

.aero-inetra-logo {
  width: 220px;
}

.aero-copyright-div {
  display: flex;
  width: 112px;
  border-right: 1px solid rgba(37, 33, 92, 0.3);
}

.aero-copyright-text {
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  position: absolute;
  top: 280px;
  left: -112px;
}

.aero-user-feedback-disclaimer {
  color: #ffffff;
  font-size: 14px;
}

.aero-user-feedback-text {
  color: #ffffff;
  font-size: 14px;
}

.aero-user-feedback-btndiv {
  padding-right: 40px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1024px) {
  .aero-row-margin {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .aero-inetra-logo {
    width: 160px;
  }

  .aero-user-feedback-disclaimer {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .aero-user-feedback-text {
    font-size: 12px;
    margin-top: 0px;
  }

  .aero-user-feedback-btndiv {
    padding-right: 16px;
  }

  .aero-home-container {
    overflow: auto;
  }

  .aero-left-margin {
    margin-left: 20px;
  }

  .aero-menu-container {
    padding-right: 20px;
  }

  .aero-copyright-div {
    width: 100px;
  }

  .aero-copyright-text {
    top: 300px;
    left: -94px;
  }
}